import revive_payload_client_2jB8tzV4LJ from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.5.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_QDE3iI4zzX from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.5.2_vite@5.4.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_wP3on6gExE from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.5.2_vite@5.4.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_X9rtVQ5TBn from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.5.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_nXhqngBKAM from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.5.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8G4063P7H6 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.5.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_wDF8VUQrM0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.5.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _64nuxt_scripts_D34jXE4xNh from "/vercel/path0/abmeldung-digital/.nuxt/modules/@nuxt-scripts.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/abmeldung-digital/.nuxt/components.plugin.mjs";
import prefetch_client_hUECVmU7wb from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.9.0_eslint@8.57.0_sass@1.78.0_typescript@5.5.2_vite@5.4.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_tQRlxBJGYX from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.15.1_vite@5.4.5_vue@3.5.4/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_Fba3NNknIz from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.15.1_vite@5.4.5_vue@3.5.4/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_7JVKZiHXo0 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.15.1_vite@5.4.5_vue@3.5.4/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_fiwUwL0oBg from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import event_directive_iS46eQdIJo from "/vercel/path0/layers/google-analytics/plugins/event-directive.ts";
import script_6zAVbHB04w from "/vercel/path0/layers/google-analytics/plugins/script.ts";
import feathers_GSPs2K4t3q from "/vercel/path0/layers/shop-api-feathers/plugins/feathers.ts";
import cookie_banner_client_gpu0cuWAIt from "/vercel/path0/layers/base/plugins/cookie-banner.client.ts";
import trpc_UmSBE3Wgkx from "/vercel/path0/layers/base/plugins/trpc.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/abmeldung-digital/plugins/sentry.client.ts";
export default [
  revive_payload_client_2jB8tzV4LJ,
  unhead_QDE3iI4zzX,
  router_wP3on6gExE,
  payload_client_X9rtVQ5TBn,
  navigation_repaint_client_nXhqngBKAM,
  check_outdated_build_client_8G4063P7H6,
  chunk_reload_client_wDF8VUQrM0,
  _64nuxt_scripts_D34jXE4xNh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_hUECVmU7wb,
  slideovers_tQRlxBJGYX,
  modals_Fba3NNknIz,
  colors_7JVKZiHXo0,
  plugin_client_fiwUwL0oBg,
  event_directive_iS46eQdIJo,
  script_6zAVbHB04w,
  feathers_GSPs2K4t3q,
  cookie_banner_client_gpu0cuWAIt,
  trpc_UmSBE3Wgkx,
  sentry_client_shVUlIjFLk
]